import React, { useState, useEffect, useRef } from 'react';

import { RiTwitterXFill } from "react-icons/ri";
import { FaTelegram } from "react-icons/fa";

// import logo from "../img/logo.svg";
// import logo2 from "../img/logo_on_hover.svg";
// import { Link, NavLink } from "react-router-dom";




function Navbar() {
  // let resolved = useResolvedPath(to);
  // let match = useMatch({ path: resolved.pathname, end: true });

  //   const [scroll, setScroll] = useState(false);

  //  useEffect(() => {
  //    window.addEventListener("scroll", () => {
  //      setScroll(window.scrollY > 80);
  //    });
  //  }, []); 


  // const scroll = useHasScrolled(100)

  const [click, setClick, isChecked] = useState(false);
  const [button, setButton] = useState(true);


  const ref = useRef(null);
  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => {
    setClick(false);
    ref.current.checked = false;
  }

  const showButton = () => {
    if (window.innerWidth <= 992) {
      setButton(false);
    } else {
      setButton(true);
    }
  };


  return (
    <>
      <div >
        <div>
          <div >
            <nav className="navbar" id="header">
              <div className='navbar-container'>
                <a href="/" className='logo' onClick={closeMobileMenu}>
                  {/* <img width={52} src={logo2} /><img width={52} src={logo} className="logo-hover" /> */}
                  
                </a>
                <div className='menu-icon'>

                  {/* <span className='icon'><AiOutlineMenu/></span> */}
                  <input type="checkbox" id="checkbox1" className="checkbox1 visuallyHidden" onClick={handleClick} defaultChecked={false} ref={ref} />
                  <label htmlFor="checkbox1" id='checkbox-hamburger-menu'>
                    <span className='text align-middle'>MENU</span>
                    <span className="hamburger hamburger1 align-middle">
                      <span className="bar bar1"></span>
                      <span className="bar bar2"></span>
                      <span className="bar bar3"></span>
                      <span className="bar bar4"></span>
                    </span>
                  </label>


                </div>
                <ul className={click ? 'nav-menu active' : 'nav-menu'}>


                  <li className='nav-item'>
                    <a href="/"
                      className='nav-links' onClick={closeMobileMenu}>Home</a>
                  </li>



                  <li className='nav-item'>
                    <a href="/#vision" className='nav-links' onClick={closeMobileMenu}>Vision</a>
                  </li>

                  <li className='nav-item'>
                    <a href="/#how-to-buy" className='nav-links' onClick={closeMobileMenu}>How to buy</a>
                  </li>
                 

                  <li className='nav-item'>
                    <a href="/#tokenomics" className='nav-links' onClick={closeMobileMenu}>Tokenomics</a>
                  </li>

                  <li className=''>
                  <span className="me-3"> <a href="https://twitter.com/censordog" target='_blank' ><RiTwitterXFill /></a></span><span><a href="https://t.me/cedoontonchat" target='_blank'><FaTelegram /></a></span>
   
                  </li>
                </ul>

              </div>
            </nav>
          </div>
        </div>
      </div>
    </>
  );
}

export default Navbar;