import logo from './logo.svg';
import './styles/global.scss';
import { useState, useEffect } from "react";
import { IoWalletOutline } from "react-icons/io5";
import { RiExchange2Line } from "react-icons/ri";
import { MdCurrencyExchange } from "react-icons/md";
import { RiScalesLine } from "react-icons/ri";
import { LiaBurnSolid } from "react-icons/lia";
import { FaPercent } from "react-icons/fa6";
import { GoShieldLock } from "react-icons/go";
import { RiTwitterXFill } from "react-icons/ri";
import { FaTelegram } from "react-icons/fa";
import { useHasScrolled } from './components/useHasScroll';
import Navbar from './components/Navbar';

function App() {
  const scroll = useHasScrolled(80);

  const [loading, setLoading] = useState(true);
  useEffect(() => {
    if (loading) {
      setTimeout(() => {
      setLoading(false);
    }, 1400);
    }
  }, [loading]);

  if (loading) return <><div className="loader"><img src="/loader.png" alt="loading..." className="img-fluid" /> </div></>;

  return (
    <div className={scroll ? "scrolled" : ""}>
      <Navbar />
      {/* <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
      </header> */}

      <section className='hero justify-content-end'>

        <div className='controls'>
          <h1 className='text-center mb-4'>REDO <span>KILLER</span></h1>
          <div className='text-center'>

            <a href='https://app.ston.fi/swap?outputCurrency=EQC-qaq_BiZ-zaDck-el_-BXstcLY1mdwh4XJcREJsnk9ITO&chartVisible=false&ft=TON&tt=EQC-qaq_BiZ-zaDck-el_-BXstcLY1mdwh4XJcREJsnk9ITO' target='_blank'>
              <button type="button" className='btn btn-primary mx-4 mb-sm-0 mb-3'>
                Buy $CEDO
              </button>
            </a>

            <a href='https://t.me/cedoontonchat' target='_blank'>
              <button type="button" className='btn btn-outline-secondary'>
                Join Community
              </button>
            </a>


          </div>
        </div>

      </section>

      <section className='text-center mb-5'>
        <a href='https://www.geckoterminal.com/ton/pools/EQAnXygG7Mi9EZUZE1EnZMi5onKJ03vwutIl6risx1t6I_cl' target='_blank'>
          <img src='/geckoterminal_dark.png' width={200} />
        </a>
      </section>
      <section className='container vision section' id='vision'>
        <div className='row'>
          <div className='col-12 text-center'>
            <h1>Our <span>Vision</span></h1>
            <p>
              In every epic tale, the line between hero and villain defines the journey. For us at <strong>CEDO</strong>,
              our mission is crystal clear: to challenge and ultimately <strong>dethrone REDO</strong>, the antagonist in
              our shared story within <strong>the TON ecosystem</strong>. CEDO stands as the <strong>hero in this digital saga</strong>, committed to restoring balance and bringing progressive change. Our goal is not just to confront the prevailing forces but to redefine the narrative, ensuring that our community leads the way in shaping a more equitable digital future.
            </p>


          </div>
        </div>

        <div className='row'>
          <div className='col-12 col-xl-8 offset-lg-2 '>
            <p>
              <img src='/cedo-redo.jpg' className='img-fluid my-5' />
            </p>
          </div>
        </div>

        <div className='row'>
          <div className='col-12 text-center'>

            <p>
              <strong>Join us</strong> as we embark on this bold quest to transform our network and establish
              a new era of leadership and innovation.
            </p>


          </div>
        </div>
      </section>


      <section className='container owned section' id='owned'>
        <div className='row'>
          <div className='col-12 text-center'>
            <h1>Owned by <span>Community</span></h1>
            <p>
              After the original developer stepped away, <strong>CEDO has truly become a project of the people</strong>. Seized and revitalized by its passionate community,
              CEDO now thrives under a <strong>new identity with fresh initiatives and vibrant engagement channels</strong>.
              We've launched a new Twitter to connect, a redesigned website for better access, and an active Telegram group to foster ongoing dialogue.
            </p>
            <p>
              Importantly, the project stands on the <strong>pillars of equality and transparency</strong>: no single entity holds a majority of the supply,
              and the token ownership is fully renounced with the liquidity pool (LP) permanently burned.
              This ensures that <strong>CEDO remains by and for the community</strong>, a testament to collective ambition and shared governance.
            </p>


          </div>
        </div>
      </section>

      <section className='container how-to-buy section' id='how-to-buy'>
        <div className='row'>

          <div className='col-12'>
            <h1 className='text-center'>How to <span>Buy</span></h1>
            <div className='row'>


              <div className='col-12 col-xl-10 offset-xl-1'>
                <div className='row'>
                  <div className='col-12 col-lg-4 mb-4'>
                    <div className='card-wrapper h-100'>
                      <div className='icon-wrapper'>
                        <IoWalletOutline />
                      </div>
                      <h2 className='h3'>
                        Get Wallet
                      </h2>
                      <p className='mb-0'>
                        To swap $CEDO tokens, download first the Tonkeeper wallet.
                        Or any for TON blockchain
                      </p>
                    </div>
                  </div>

                  <div className='col-12 col-lg-4 mb-4'>
                    <div className='card-wrapper h-100'>
                      <div className='icon-wrapper'>
                        <RiExchange2Line />
                      </div>
                      <h2 className='h3'>
                        Bridge
                      </h2>
                      <p className='mb-0'>
                        Bridge from any chain to TON Network.
                        Use RocketX, FixedFloat or a CEX
                      </p>
                    </div>
                  </div>

                  <div className='col-12 col-lg-4 mb-4'>
                    <div className='card-wrapper h-100'>
                      <div className='icon-wrapper'>
                        <MdCurrencyExchange />
                      </div>
                      <h2 className='h3'>
                        Buy on DEX
                      </h2>
                      <p className='mb-0'>
                        Swap TON for CEDO.
                        Use Ston.fi using our Contract adress
                      </p>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className='container contract section text-center'>
        <div className='row'>

          <div className='col-12'>
            <h1 className=''>Contract <span>Address</span></h1>
            <p>EQC-qaq_BiZ-zaDck-el_-BXstcLY1mdwh4XJcREJsnk9ITO </p>
          </div>
        </div>

      </section>
      <section className='container tokenomics section' id='tokenomics'>

        <div className='row'>

          <div className='col-12'>
            <h1 className='text-center'>Tokenomics</h1>
            <div className='row'>

              <div className='col-6 col-md-6 col-lg-3 mb-4'>
                <div className='card-wrapper h-100'>
                  <div className='icon-wrapper'>
                    <RiScalesLine />
                  </div>
                  <h2 className='h3'>
                    Total Supply
                  </h2>
                  <p className='mb-0'>
                    100,000,000
                  </p>
                </div>
              </div>

              <div className='col-6 col-md-6 col-lg-3 mb-4'>
                <div className='card-wrapper h-100'>
                  <div className='icon-wrapper'>
                    <LiaBurnSolid />
                  </div>
                  <h2 className='h3'>
                    LP Burned
                  </h2>
                  <p className='mb-0'>
                    Anyone can add it
                  </p>
                </div>
              </div>

              <div className='col-6 col-md-6 col-lg-3 mb-4'>
                <div className='card-wrapper h-100'>
                  <div className='icon-wrapper'>
                    <FaPercent />
                  </div>
                  <h2 className='h3'>
                    Taxes
                  </h2>
                  <p className='mb-0'>
                    0/0
                  </p>
                </div>
              </div>

              <div className='col-6 col-md-6 col-lg-3 mb-4'>
                <div className='card-wrapper h-100'>
                  <div className='icon-wrapper'>
                    <GoShieldLock />
                  </div>
                  <h2 className='h3'>
                    Contract
                  </h2>
                  <p className='mb-0'>
                    Renounced
                  </p>
                </div>
              </div>

            </div>
          </div>
        </div>
      </section>



      <section id="footer" className="w-100  h-100 text-center d-flex flex-column justify-content-end section">



        <a
          href="/#"          >

          <img
            src="/logo.png"
            alt="Otton Emperor"

            width={120}


          />
        </a>
        <p className="m-3 fs-4">
          <span className="me-3"> <a href="https://twitter.com/censordog" target='_blank' ><RiTwitterXFill /></a></span><span><a href="https://t.me/cedoontonchat" target='_blank'><FaTelegram /></a></span>
        </p>


        <p className="text-center f-14">
          Copyright 2024 Censor Dog. All rights reserved<br />

        </p>

      </section>
    </div>
  );
}

export default App;
